@import "node_modules/bootstrap/scss/bootstrap";
@import "assets/styles/colors";

.card-header {
  display: flex;
  align-items: center;
  h1 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: justify;
    color: #4b5056;
    margin: 0px 0px 0px 10px;
  }
}

.cursorDefault {
  cursor: default !important;
}

.cursorPointer {
  cursor: pointer !important;
}

a {
  color: $orange;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: none !important;
  color: #00ef1d !important;
}

a.button:hover {
  color: #fff !important;
}
.form-control::-webkit-input-placeholder {
  color: rgb(226, 226, 226);
}
